import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dropdown as SemanticUIDropdown } from "semantic-ui-react";
import { Box, Flex, BoxProps } from "@otrium/core";
import { omit } from "lodash-es";
import styled from "@emotion/styled";
import { EmotionBaseProps as BaseProps } from "src/types/global";
import { useClientRect } from "src/hooks/useClientRect";
import { Error } from "src/atoms/Input/Input.styled";
import { Theme } from "@emotion/react";

const SemanticUIDropdownWrapper = (props: any) => (
  <>
    <SemanticUIDropdown
      selection
      selectOnBlur={false}
      selectOnNavigation={false}
      {...omit(props, ["errorMessage"])}
    />
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
    {props && props.errorMessage && (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      <Error data-testid="dropdown-error">{props.errorMessage}</Error>
    )}
  </>
);

/* eslint-disable no-underscore-dangle */
const Dropdown = styled(SemanticUIDropdownWrapper)`
  min-width: 100px;
  height: 48px;
  position: relative;
  background: ${({ theme }: { theme: Theme }) => theme.colors?.tone.white};
  text-align: left;
  padding: 0;
  outline: 0;
  word-wrap: break-word;
  white-space: normal;
  -webkit-tap-highlight-color: #0000;
  user-select: none;
  cursor: pointer;
  border: 1px solid
    ${({ theme }: { theme: Theme }) => theme.colors?.tone.silver};
  border-radius: 4px;
  flex: 1;
  &.disabled {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors?.tone.sand};
    cursor: default;

    > .text {
      color: ${({ theme }: { theme: Theme }) =>
        theme.colors?.tone.anthraciteLight};
    }

    i.icon {
      &:before {
        background: url(/static/images/chevron-down-disabled.svg) no-repeat
          center;
      }
    }
  }

  > .text {
    margin: 12px 42px 12px 16px;

    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;

    color: ${({ theme }: { theme: Theme }) => theme.colors?.tone.black};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.default {
      color: ${({ theme }: { theme: Theme }) =>
        theme.colors?.tone.anthraciteLight};
    }
  }

  i.icon {
    position: absolute;
    top: 11px;
    right: 15px;
    &:before {
      content: "";
      display: inline-block;
      mask-image: url(/static/images/chevron-down.svg);
      background-color: ${({ theme }: { theme: Theme }) =>
        theme.colors?.tone.anthraciteLight};
      width: 12px;
      height: 7px;
    }
  }

  .menu {
    position: absolute;
    background: ${({ theme }: { theme: Theme }) => theme.colors?.tone.white};
    top: 100%;
    left: 0;
    width: 100%;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    z-index: 1100;
    max-height: 240px;

    &.visible {
      display: block;
      border: 1px solid
        ${({ theme }: { theme: Theme }) => theme.colors?.tone.silver};
      margin: 0 -1px;
      width: calc(100% + 2px);
    }
    .item {
      position: relative;
      cursor: pointer;
      box-shadow: none;
      -webkit-touch-callout: none;
      padding: 9px 16px;

      color: ${({ theme }: { theme: Theme }) => theme.colors?.tone.black};

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.4px;
      }

      .description {
        float: right;
        font-size: ${({ theme }: { theme: Theme }) =>
          theme.fontSizes?.fontSize14};
        line-height: 17.6px;
        color: ${({ theme }: { theme: Theme }) =>
          theme.colors?.tone.anthraciteLight};
      }

      &.selected {
        background: ${({ theme }: { theme: Theme }) => theme.colors?.tone.sand};
      }

      &:hover {
        background: ${({ theme }: { theme: Theme }) => theme.colors?.tone.sand};
      }
    }
  }

  &.active {
    .icon {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  &.upward {
    .menu {
      top: auto;
      bottom: 100%;
    }
  }
`;

type Clickable = {
  onClick?: () => void;
};

const DropdownInline = styled(Dropdown)`
  border: none;
  height: auto;
  min-width: auto;
  flex: 0;
  i.icon {
    top: 1px;
    right: 0;
    &:before {
      transition: all 0.2s;
    }
  }

  .menu {
    right: 0;
    left: auto;
    max-height: 200px;
    top: calc(100% + 13px);
    z-index: ${({ theme }: { theme: Theme }) => theme.zIndex?.popup};

    &.visible {
      width: 288px;
      border: none;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }

    .item {
      padding: 8px 32px 8px 16px;
    }
  }

  > .text {
    margin: 0 16px 0 0;
    font-weight: 600;
  }

  &.active {
    .icon {
      &:before {
        background-color: ${({ theme }: { theme: Theme }) =>
          theme.colors?.tone.black};
      }
    }
  }
`;

const DropdownOutlinedWrapper = styled(Box)`
  position: relative;
`;

const DropdownButton = styled(Flex)<{ opened?: boolean; disabled?: boolean }>`
  align-items: center;
  padding: 3px 16px 5px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors?.tone.silver};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  > span {
    font-size: ${({ theme }) => theme.fontSizes?.fontSize16};
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors?.tone.silver : theme.colors?.tone.black};
    line-height: 1.5;
    letter-spacing: 0.4px;
    position: relative;
    padding-right: 21px;
    &:after {
      position: absolute;
      content: "";
      mask-image: url(/static/images/chevrons-default.svg);
      background-color: ${({ theme, disabled }) =>
        disabled
          ? theme.colors?.tone.silver
          : theme.colors?.tone.anthraciteLight};
      width: 12px;
      height: 7px;
      top: 10px;
      right: 0;
      transition: all 0.2s;
      ${({ opened, theme }) =>
        opened &&
        `
        transform: rotate(180deg);
        background-color: ${theme.colors?.tone.black}
        `}
    }
  }
`;

const DropdownItemsWrapper = styled(Box)<{ left?: number }>`
  position: absolute;
  top: calc(100% + 8px);
  display: grid;
  grid-template-rows: 0fr;
  width: 288px;
  max-height: 420px;
  padding: 0 ${({ theme }) => theme.space?.space16};
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  z-index: 13;
  background-color: ${({ theme }) => theme.colors?.tone.white};
  overflow: hidden;
  ${({ left }) => (left ? `left: ${left}px;` : "")}

  transition: grid-template-rows 250ms ease-in-out;

  &.open {
    grid-template-rows: 1fr;
  }
`;

const DropdownItemsInner = styled.div`
  min-height: 0;
  max-height: inherit;
  visibility: hidden;
  transition: visibility 250ms ease-in-out;

  &.open {
    visibility: visible;
  }
`;

const DropdownItems: FC<{
  opened?: boolean;
  wrapperRef?: RefObject<HTMLDivElement>;
  children?: ReactNode;
}> = ({ children, opened, wrapperRef, ...rest }) => {
  const [left, setLeft] = useState(0);
  const rect = useClientRect(wrapperRef as RefObject<HTMLDivElement>);

  useEffect(() => {
    const x = window.innerWidth - (rect.left + 288 + 22);
    setLeft(Math.min(x, 0));
  }, [rect]);

  return (
    <DropdownItemsWrapper
      {...rest}
      left={left}
      className={opened ? "open" : ""}
    >
      <DropdownItemsInner className={opened ? "open" : ""}>
        {children}
      </DropdownItemsInner>
    </DropdownItemsWrapper>
  );
};

const DropdownOutlined: FC<
  PropsWithChildren<
    BoxProps & BaseProps & { opened?: boolean; disabled?: boolean }
  >
> & {
  Button: typeof DropdownButton;
  Items: typeof DropdownItems;
} = ({ opened, disabled, children, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <DropdownOutlinedWrapper ref={ref} {...rest}>
      {Children.map(children, (child) =>
        isValidElement(child)
          ? cloneElement<any>(child, {
              opened,
              disabled,
              wrapperRef: ref,
              ...((child.props as Clickable).onClick
                ? {
                    onClick: disabled
                      ? null
                      : (child.props as Clickable).onClick,
                  }
                : {}),
            })
          : child
      )}
    </DropdownOutlinedWrapper>
  );
};

DropdownOutlined.Button = DropdownButton;
DropdownOutlined.Items = DropdownItems;

export { Dropdown, DropdownInline, DropdownOutlined };
